import React, { useState, useEffect } from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../services/firebase";
import "./Consultations.css";

const Consultations = () => {
  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    const subscribeToConsultations = () => {
      const docRef = doc(db, "consultations", "allConsultations");
      return onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            const consultationsArray = Object.entries(data).map(
              ([id, consultation]) => ({
                id,
                ...consultation,
              })
            );
            const sortedConsultations = consultationsArray.sort(
              (a, b) => b.createdAt.toDate() - a.createdAt.toDate()
            );
            setConsultations(sortedConsultations);
          }
        },
        (error) => {
          console.error("Error fetching consultations:", error);
        }
      );
    };

    const unsubscribe = subscribeToConsultations();
    return () => unsubscribe();
  }, []);
  async function checked(e, id) {
    await setDoc(
      doc(db, "consultations", "allConsultations"),
      {
        [id]: { completed: e.target.checked },
      },
      { merge: true }
    );
  }
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="consultations-container">
              <Card className="mb-4">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Active Consultations</h5>
                  <Badge bg="primary" pill>
                    {consultations.filter((c) => !c.completed).length} Active
                  </Badge>
                </Card.Header>
                <Card.Body>
                  <Row xs={1} md={2} lg={3} className="g-4">
                    {consultations.filter((c) => !c.completed).length === 0 ? (
                      <Col className="text-center">
                        <Card className="consultation-card h-100">
                          <Card.Body>
                            <div className="text-center text-muted">
                              No active consultations available
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ) : (
                      consultations.map(
                        (consultation) =>
                          !consultation.completed && (
                            <Col key={consultation.id}>
                              <Card className="consultation-card h-100">
                                <Card.Body>
                                  <div className="d-flex justify-content-between mb-3">
                                    <div>
                                      <h6 className="mb-1">
                                        {consultation.name}
                                      </h6>
                                      <small className="text-muted d-block">
                                        {consultation.email}
                                      </small>
                                      {consultation.phone && (
                                        <small className="text-muted d-block">
                                          {consultation.phone}
                                        </small>
                                      )}
                                    </div>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={consultation.completed}
                                        onChange={(e) =>
                                          checked(e, consultation.id)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <Card.Text className="message-box">
                                    {consultation.message}
                                  </Card.Text>
                                  <div className="mt-3 pt-3 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <small className="text-muted">
                                        {consultation.createdAt
                                          .toDate()
                                          .toLocaleString()}
                                      </small>
                                      {consultation.mediaUrl && (
                                        <a
                                          href={consultation.mediaUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-sm btn-outline-primary"
                                        >
                                          Download Media
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          )
                      )
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Consultations;
