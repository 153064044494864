import React from "react";
import Booking from "../components/Booking";

const BookingPage = () => {
  return (
    <div className="booking-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="booking-page-wrapper">
              <Booking />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
