import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./SignIn.css";

const SignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate("/dashboard");
    } catch (err) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };
  // Remove handleGoogleSignIn function

  return (
    <div className="min-vh-100 bg-gradient">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6} xl={5}>
            <div className="bg-white shadow-lg rounded-4 overflow-hidden">
              <div className="brand-header text-center py-4">
                <img src="/logo.svg" alt="Logo" width="64" height="64" />
                <p className="brand-subtitle">Consumer Protection Networks</p>
                <div className="portal-badge">Client Portal</div>
              </div>

              <div className="p-4 p-md-5">
                <div className="text-center mb-4">
                  <h2 className="welcome-text">Welcome Back</h2>
                  <p className="text-muted">Access your account securely</p>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}

                <Form onSubmit={handleSubmit} className="mb-4">
                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaEnvelope className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email Address"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaLock className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 py-3 custom-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Sign In to Portal"
                    )}
                  </Button>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Link
                      to="/forgot-password"
                      className="text-decoration-none forgot-link"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </Form>
              </div>

              <div className="footer-section text-center py-3">
                <p className="mb-0">
                  Need an account?{" "}
                  <Link to="/signup" className="signup-link">
                    Sign up now
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignIn;
