import React, { useEffect, useState } from "react";
import { Container, Button, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import "./dashboard.css";
import Chat from "../../components/Chat/Chat";
// import InviteUser from "../../components/InviteUser/InviteUser";
import {
  BsGrid,
  // BsFillPersonPlusFill,
  BsChat,
  BsBoxArrowRight,
  BsList,
} from "react-icons/bs";
import TimeshareList from "../../components/TimeshareList/TimeshareList";
import Consultations from "../../components/Consultations/Consultations";

function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUser({ uid: userDocSnap.id, ...userDocSnap.data() });
          setIsAdmin(userDocSnap.data()?.isAdmin || false);
          setActiveTab(isAdmin ? "consultations" : "timeshare");
        }
      } else {
        navigate("/SignIn");
      }
    });

    return () => unsubscribe();
  }, [navigate, isAdmin]);

  if (!user) {
    return null;
  }

  const handleLogout = () => {
    auth.signOut();
    navigate("/SignIn");
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    document.body.classList.toggle("body-pd");
    document.getElementById("header").classList.toggle("body-pd-header");
  };

  return (
    <div className="dashboard" id="body-pd">
      <Navbar bg="light" className="header" id="header" fixed="top">
        <Container fluid>
          <Button
            variant="link"
            className="header_toggle"
            onClick={toggleSidebar}
          >
            <BsList />
          </Button>
          <div className="header_img">
            <img src={user.photoURL} alt="Profile" />
          </div>
        </Container>
      </Navbar>

      <div className={`l-navbar ${showSidebar ? "show" : ""}`} id="nav-bar">
        <Nav className="nav flex-column">
          <div>
            <Nav.Link className="nav_logo">
              <img
                src="/logo.svg"
                alt="Logo"
                className="nav_logo-icon company-logo"
              />
              <span className="nav_logo-name">
                {isAdmin ? "Dashboard" : "Client Portal"}
              </span>
            </Nav.Link>

            <div className="nav_list">
              {isAdmin ? (
                <>
                  <Nav.Link
                    className={`nav_link ${
                      activeTab === "consultations" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("consultations")}
                  >
                    <BsGrid className="nav_icon" />
                    <span className="nav_name">Consultations</span>
                  </Nav.Link>
                  {/* 
                  <Nav.Link
                    className={`nav_link ${
                      activeTab === "inviteUser" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("inviteUser")}
                  >
                    <BsFillPersonPlusFill className="nav_icon" />
                    <span className="nav_name">Invitation</span>
                  </Nav.Link> */}

                  <Nav.Link
                    className={`nav_link ${
                      activeTab === "inbox" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("inbox")}
                  >
                    <BsChat className="nav_icon" />
                    <span className="nav_name">Messages</span>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link
                    className={`nav_link ${
                      activeTab === "timeshare" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("timeshare")}
                  >
                    <BsGrid className="nav_icon" />
                    <span className="nav_name">My Timeshare</span>
                  </Nav.Link>

                  <Nav.Link
                    className={`nav_link ${
                      activeTab === "inbox" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("inbox")}
                  >
                    <BsChat className="nav_icon" />
                    <span className="nav_name">Messages</span>
                  </Nav.Link>
                </>
              )}
            </div>
          </div>

          <Nav.Link className="nav_link" onClick={handleLogout}>
            <BsBoxArrowRight className="nav_icon" />
            <span className="nav_name">Sign Out</span>
          </Nav.Link>
        </Nav>
      </div>

      <div className={`height-100`}>
        <Container fluid className="p-0">
          {activeTab === "consultations" && isAdmin && <Consultations />}
          {activeTab === "inbox" && <Chat />}
          {/* {activeTab === "inviteUser" && isAdmin && <InviteUser />} */}
          {activeTab === "timeshare" && !isAdmin && (
            <TimeshareList uid={user.uid} />
          )}
        </Container>
      </div>
    </div>
  );
}

export default Dashboard;
