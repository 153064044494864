import "./chat.css";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  addDoc,
  getDoc,
  doc,
  limit,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "../../services/firebase";
import {
  IoSend,
  IoDocument,
  IoClose,
  IoAttach,
  IoArrowBack,
} from "react-icons/io5";

const Chat = () => {
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const currentUser = auth.currentUser;
  const [unreadCounts, setUnreadCounts] = useState({});

  useEffect(() => {
    const userDocRef = doc(db, "users", currentUser.uid);
    getDoc(userDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setIsAdmin(docSnap.data().isAdmin || false);
      }
    });
  }, [currentUser]);

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Add function to mark messages as read
  const markMessagesAsRead = useCallback(async () => {
    if (!selectedUser) return;

    const messagesQuery = query(
      collection(db, "messages"),
      where("receiver", "==", currentUser.uid),
      where("sender", "==", selectedUser.uid),
      where("read", "==", false)
    );

    const unreadMessages = await getDocs(messagesQuery);

    const batch = writeBatch(db);
    unreadMessages.docs.forEach((doc) => {
      batch.update(doc.ref, { read: true });
    });

    await batch.commit();
  }, [selectedUser, currentUser]);

  useEffect(() => {
    if (isAdmin) {
      const usersQuery = query(
        collection(db, "users"),
        where("isAdmin", "==", false),
        orderBy("lastMessageTimestamp", "desc")
      );

      const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
        const usersList = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
        setIsLoadingUsers(false);
      });

      return () => unsubscribe();
    } else {
      const usersQuery = query(
        collection(db, "users"),
        where("isAdmin", "==", true),
        orderBy("lastMessageTimestamp", "desc")
      );

      const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
        const usersList = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
        setIsLoadingUsers(false);
      });
      return () => unsubscribe();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (selectedUser) {
      setIsLoadingMessages(true);
      const messagesQuery = query(
        collection(db, "messages"),
        where("sender", "in", [currentUser.uid, selectedUser.uid]),
        where("receiver", "in", [currentUser.uid, selectedUser.uid]),
        orderBy("timestamp", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messagesList = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        // Sort messages by timestamp in ascending order
        const sortedMessages = [...messagesList].sort(
          (a, b) => a.timestamp.toMillis() - b.timestamp.toMillis()
        );
        setMessages(sortedMessages);
        setIsLoadingMessages(false);
        markMessagesAsRead();
      });

      return () => unsubscribe();
    } else {
      setMessages([]); // Clear messages when no user is selected
    }
  }, [selectedUser, currentUser, markMessagesAsRead]);

  // Update handleSendMessage to update both users' lastMessageTimestamp
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !attachment) return;

    try {
      setIsSending(true);
      let attachmentURL = null;
      if (attachment) {
        // Check if file is an image or PDF
        if (
          attachment.type.startsWith("image/") ||
          attachment.type === "application/pdf"
        ) {
          const storageRef = ref(
            storage,
            `chat/${selectedUser.uid}/${Date.now()}_${attachment.name}`
          );
          await uploadBytes(storageRef, attachment);
          attachmentURL = await getDownloadURL(storageRef);
        } else {
          throw new Error("Only image and PDF files are allowed");
        }
      }

      const messageData = {
        text: newMessage.trim() || null,
        attachment: attachmentURL,
        attachmentType: attachment
          ? attachment.type.startsWith("image/")
            ? "image"
            : attachment.type === "application/pdf"
            ? "pdf"
            : null
          : null,
        sender: currentUser.uid,
        receiver: selectedUser.uid,
        timestamp: new Date(),
        read: false,
      };

      // Add message
      await addDoc(collection(db, "messages"), messageData);

      // Update timestamp for both users
      const batch = writeBatch(db);
      batch.update(doc(db, "users", currentUser.uid), {
        lastMessageTimestamp: messageData.timestamp,
      });
      batch.update(doc(db, "users", selectedUser.uid), {
        lastMessageTimestamp: messageData.timestamp,
      });
      await batch.commit();

      setNewMessage("");
      setAttachment(null);
      setAttachmentPreview(null);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      setAttachmentPreview({
        name: file.name,
        size: (file.size / 1024).toFixed(2) + " KB",
        type: file.type,
      });
    }
  };

  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  };

  useEffect(() => {
    const chatListItems = document.querySelectorAll(".chat-list a");
    const chatIcon = document.querySelector(".chat-icon");
    const chatbox = document.querySelector(".chatbox");

    if (chatListItems.length > 0 && chatIcon && chatbox) {
      chatListItems.forEach((item) => {
        item.addEventListener("click", (e) => {
          e.preventDefault();
          chatbox.classList.add("showbox");
        });
      });

      chatIcon.addEventListener("click", () => {
        chatbox.classList.remove("showbox");
      });

      return () => {
        chatListItems.forEach((item) => {
          item.removeEventListener("click", () => {});
        });
        chatIcon.removeEventListener("click", () => {});
      };
    }
  }, []);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Add useEffect to count unread messages
  useEffect(() => {
    if (!currentUser) return;

    const unreadQuery = query(
      collection(db, "messages"),
      where("receiver", "==", currentUser.uid),
      where("read", "==", false)
    );

    const unsubscribe = onSnapshot(unreadQuery, (snapshot) => {
      const counts = {};
      snapshot.docs.forEach((doc) => {
        const message = doc.data();
        counts[message.sender] = (counts[message.sender] || 0) + 1;
      });
      setUnreadCounts(counts);
    });

    return () => unsubscribe();
  }, [currentUser]);

  // Modify the useEffect for selected user to mark messages as read
  useEffect(() => {
    if (selectedUser) {
      setIsLoadingMessages(true);
      const messagesQuery = query(
        collection(db, "messages"),
        where("sender", "in", [currentUser.uid, selectedUser.uid]),
        where("receiver", "in", [currentUser.uid, selectedUser.uid]),
        orderBy("timestamp", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messagesList = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setMessages([...messagesList].reverse());
        setIsLoadingMessages(false);
        markMessagesAsRead(); // Mark messages as read when viewing conversation
      });

      return () => unsubscribe();
    }
  }, [selectedUser, currentUser, markMessagesAsRead]);

  // Update the user list rendering to show unread count
  return (
    <section className="message-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="chat-area">
              {/* chatlist */}
              <div className="chatlist">
                <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="chat-header">
                      {isAdmin ? (
                        <div className="msg-search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            aria-label="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="msg-search">
                          <div className="d-flex align-items-center support-header">
                            <div className="flex-grow-1 text-center support-content">
                              <h3 className="support-title">
                                Welcome to Customer Support
                              </h3>
                              <p className="support-instruction">
                                Our support team is online and ready to help
                                <span className="blink-arrow pulse">↓</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="modal-body">
                      <div className="chat-lists">
                        <div className="chat-list">
                          {isLoadingUsers ? (
                            <div className="text-center p-3">
                              Loading users...
                            </div>
                          ) : (
                            filteredUsers.map((user) => (
                              <button
                                key={user.uid}
                                className="d-flex w-100 border-0 bg-transparent"
                                onClick={() => setSelectedUser(user)}
                              >
                                <div className="flex-shrink-0">
                                  <img
                                    className="img-fluid"
                                    src={user.photoURL}
                                    alt={user.name}
                                  />
                                </div>
                                <div className="flex-grow-1 text-start ms-3">
                                  <h3>{user.name}</h3>
                                  <p>{user.email}</p>
                                </div>
                                {unreadCounts[user.uid] > 0 && (
                                  <span className="unread-badge">
                                    {unreadCounts[user.uid]}
                                  </span>
                                )}
                              </button>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* chatbox */}
              <div className={`chatbox ${selectedUser ? "showbox" : ""}`}>
                <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    {selectedUser && (
                      <>
                        <div className="msg-head">
                          <div className="row">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <span className="chat-icon">
                                  <IoArrowBack
                                    size={24}
                                    onClick={() => setSelectedUser(null)}
                                  />
                                </span>
                                <div className="flex-shrink-0">
                                  <img
                                    className="img-fluid"
                                    src={selectedUser.photoURL}
                                    alt="user img"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h3>{selectedUser.name}</h3>
                                  {!selectedUser.isAdmin && (
                                    <p>
                                      <a
                                        href={`https://app.gohighlevel.com/v2/location/OmOFLA0kINZSjc9gGy9P/contacts/detail/${selectedUser.ghlContactId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Open in GHL
                                      </a>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="modal-body">
                          <div className="msg-body">
                            <ul>
                              {isLoadingMessages ? (
                                <div className="text-center p-3">
                                  Loading messages...
                                </div>
                              ) : (
                                messages.map((message) => (
                                  <li
                                    key={message.uid}
                                    className={
                                      message.text
                                        ? message.sender === currentUser.uid
                                          ? "sender"
                                          : "receiver"
                                        : message.sender === currentUser.uid
                                        ? "attachment-sender"
                                        : "attachment-receiver"
                                    }
                                  >
                                    {message.text && <p>{message.text}</p>}
                                    {message.attachment && (
                                      <div className="attachment">
                                        {message.attachmentType === "pdf" ? (
                                          <object
                                            data={message.attachment}
                                            type="application/pdf"
                                            width="50%"
                                            height="250px"
                                          >
                                            <a
                                              href={message.attachment}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              View PDF
                                            </a>
                                          </object>
                                        ) : (
                                          <img
                                            src={message.attachment}
                                            alt="Attachment"
                                            style={{ maxWidth: "50%" }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <span className="time">
                                      {message.timestamp
                                        ?.toDate()
                                        .toLocaleTimeString()}
                                    </span>
                                  </li>
                                ))
                              )}
                            </ul>
                            <div ref={messagesEndRef} />
                          </div>
                        </div>

                        <div className="send-box">
                          {attachmentPreview && (
                            <div className="document-preview">
                              <div className="document-preview-item">
                                <div className="document-preview-icon">
                                  <IoDocument size={24} />
                                </div>
                                <div className="document-preview-info">
                                  <p className="document-preview-name">
                                    {attachmentPreview.name}
                                  </p>
                                  <p className="document-preview-size">
                                    {attachmentPreview.size}
                                  </p>
                                </div>
                                <button
                                  className="document-preview-remove"
                                  onClick={removeAttachment}
                                  type="button"
                                >
                                  <IoClose size={20} />
                                </button>
                              </div>
                            </div>
                          )}
                          <form onSubmit={handleSendMessage}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Write message…"
                              value={newMessage}
                              onChange={(e) => setNewMessage(e.target.value)}
                            />
                            <div className="send-btns">
                              <div className="attach">
                                <div className="button-wrapper">
                                  <span className="label">
                                    <IoAttach size={22} />
                                  </span>
                                  <input
                                    type="file"
                                    accept="image/*,.pdf"
                                    onChange={handleFileSelect}
                                    className="upload-box"
                                    aria-label="Upload File"
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              className="submit-button"
                              type="submit"
                              disabled={isSending}
                            >
                              {isSending ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <IoSend size={24} />
                              )}
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chat;
