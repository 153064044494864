import React, { useState } from "react";
import "./PrivacyPolicyBooking.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const PrivacyPolicyBooking = ({ checked, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleLabelClick = (e) => {
    // Prevent expansion when clicking the label
    e.stopPropagation();
  };

  return (
    <div className="privacy-policy-booking">
      <div className="form-check">
        <input
          type="checkbox"
          id="smsConsent"
          name="smsConsent"
          className="form-check-input"
          checked={checked}
          onChange={onChange}
        />
        <label
          htmlFor="smsConsent"
          className="form-check-label"
          onClick={handleLabelClick}
        >
          <div className="consent-header">
            <div className="consent-title-wrapper">
              <span className="consent-title">
                I agree to receive SMS messages for appointment reminders and
                confirmations
              </span>
            </div>
            <button
              type="button"
              className="expand-button"
              onClick={handleExpandClick}
            >
              {isExpanded ? (
                <FiChevronUp className="toggle-icon" />
              ) : (
                <FiChevronDown className="toggle-icon" />
              )}
            </button>
          </div>
          <div className={`consent-text ${isExpanded ? "expanded" : ""}`}>
            <p>
              By checking this box, you consent to receive SMS messages from
              Consumer Protection Networks for appointment reminders and
              confirmations. Message and data rates may apply. You can cancel
              the SMS service at any time by texting "STOP" to the shortcode.
              For help, text "HELP" or contact us at{" "}
              <a href="mailto:clientservices@consumerprotectionnetworks.com">
                clientservices@consumerprotectionnetworks.com
              </a>{" "}
            </p>
            <p>
              For more information about our privacy practices, please visit our{" "}
              <a
                href="/PrivacyPolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export default PrivacyPolicyBooking;
