import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../services/firebase";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset link has been sent to your email");
      setEmail("");
    } catch (err) {
      setError(err.message || "Failed to reset password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-vh-100 bg-gradient">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6} xl={5}>
            <div className="bg-white shadow-lg rounded-4 overflow-hidden">
              <div className="brand-header text-center py-4">
                <img src="/logo.svg" alt="Logo" width="64" height="64" />
                <p className="brand-subtitle">Consumer Protection Networks</p>
                <div className="portal-badge">Reset Password</div>
              </div>

              <div className="p-4 p-md-5">
                <div className="text-center mb-4">
                  <h2 className="welcome-text">Forgot Password?</h2>
                  <p className="text-muted">
                    Enter your email to receive reset instructions
                  </p>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}

                <Form onSubmit={handleSubmit} className="mb-4">
                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaEnvelope className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Address"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 py-3 custom-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Form>
              </div>

              <div className="footer-section text-center py-3">
                <p className="mb-0">
                  Remember your password?{" "}
                  <Link to="/signin" className="signup-link">
                    Sign in here
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
