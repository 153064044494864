import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../services/firebase";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { FaLock } from "react-icons/fa";
import "./ConfirmPassword.css";

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [oobCode, setOobCode] = useState("");

  useEffect(() => {
    // Get the OOB code from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("oobCode");
    if (code) {
      setOobCode(code);
    } else {
      setError("Invalid password reset link");
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, formData.password);
      navigate("/signin", {
        state: {
          message:
            "Password has been reset successfully. Please sign in with your new password.",
        },
      });
    } catch (err) {
      setError(err.message || "Failed to reset password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-vh-100 bg-gradient">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6} xl={5}>
            <div className="bg-white shadow-lg rounded-4 overflow-hidden">
              <div className="brand-header text-center py-4">
                <img src="/logo.svg" alt="Logo" width="64" height="64" />
                <p className="brand-subtitle">Consumer Protection Networks</p>
                <div className="portal-badge">Reset Password</div>
              </div>

              <div className="p-4 p-md-5">
                <div className="text-center mb-4">
                  <h2 className="welcome-text">Create New Password</h2>
                  <p className="text-muted">Please enter your new password</p>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}

                <Form onSubmit={handleSubmit} className="mb-4">
                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaLock className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="New Password"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaLock className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm New Password"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 py-3 custom-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmPassword;
