import React from "react";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div id="privacy" style={{ padding: "20px" }}>
        <header
          style={{
            color: "#0c0c0d",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
            marginBottom: "100px",
            marginTop: "100px",
          }}
        >
          Privacy Policy
        </header>

        <p>
          At Consumer Protection Networks, we prioritize the security and
          confidentiality of your information. We specialize in legal title
          transfers to remove clients from timeshare contracts and cancellation
          of timeshare mortgage and maintenance fees. We do not share any
          personal data provided to us with third parties. Your privacy matters
          to us, and we are committed to safeguarding it at all times.
        </p>

        <h2>Information Collection:</h2>
        <p>
          We may collect personal information from you when you visit our
          website, register for an account, request our services, or interact
          with our platform. This information may include your name, email
          address, phone number, and details about your timeshare contract. Data
          may be collected through forms, cookies, or other tracking
          technologies.
        </p>

        <h2>How We Use Your Information:</h2>
        <p>
          We use the information we collect to provide and improve our timeshare
          exit services, process your requests, communicate with you about your
          case, and personalize your experience. We may also use your
          information to send you important updates about your case and other
          relevant communications.
        </p>

        <h2>Data Security:</h2>
        <p>
          We implement robust security measures to protect your personal
          information and maintain its confidentiality. We use industry-standard
          encryption protocols to secure data transmission and storage. We also
          strictly adhere to relevant data protection regulations and standards.
        </p>

        <h2>SMS Communications:</h2>
        <p>
          As part of our service, we provide appointment reminders and
          confirmations via SMS. By providing your mobile number, you consent to
          receive these text messages from Consumer Protection Networks. Message
          and data rates may apply for messages sent between us.
        </p>

        <p>
          You can cancel the SMS service at any time by texting "STOP" to the
          shortcode. We will confirm your unsubscribe status via SMS. After
          confirmation, you will no longer receive SMS messages from us. To
          rejoin, simply sign up as you did initially.
        </p>

        <p>
          For help with our messaging program, text "HELP" or contact us
          directly at{" "}
          <a href="mailto:clientservices@consumerprotectionnetworks.com">
            clientservices@consumerprotectionnetworks.com
          </a>{" "}
        </p>

        <p>
          Please note that carriers are not liable for delayed or undelivered
          messages. For questions about your text plan or data plan, please
          contact your wireless provider.
        </p>

        <h2>Updates to Privacy Policy:</h2>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be reflected on this page. We will notify you
          of significant changes through email or by posting a notice on our
          website. By using our services, you acknowledge that you have read and
          agree to these terms.
        </p>

        <p>
          If you have any questions or concerns about our Privacy Policy or
          practices, please contact us at{" "}
          <a href="mailto:clientservices@consumerprotectionnetworks.com">
            clientservices@consumerprotectionnetworks.com
          </a>{" "}
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
