import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../../services/firebase";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./SignUp.css";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createGHLContact = async (userData) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${process.env.REACT_APP_GHL_TOKEN}`
    );
    myHeaders.append("Content-Type", "application/json");

    const contactData = {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      name: `${userData.firstName} ${userData.lastName}`,
      source: "Client Portal Registration",
      tags: ["Client Portal User"],
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(contactData),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://rest.gohighlevel.com/v1/contacts/",
        requestOptions
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("GHL API Error:", errorData);
        throw new Error(errorData.message || "Failed to create contact");
      }

      const result = await response.json();
      return result.contact.id;
    } catch (error) {
      console.error("GHL API Error:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      // Create contact in GHL and get ID
      const ghlContactId = await createGHLContact(formData);

      // Create user in Firebase
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Update user profile with display name and GHL contact ID
      await updateProfile(userCredential.user, {
        displayName: `${formData.firstName} ${formData.lastName}`,
        ghlContactId: ghlContactId, // This will be accessible in the user profile
      });

      // Add user data to Firestore
      const userDocRef = doc(db, "users", userCredential.user.uid);
      await setDoc(userDocRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        photoURL: encodeURI(
          `https://eu.ui-avatars.com/api/?name=${formData.firstName} ${formData.lastName}&size=45&rounded=true`
        ),
        ghlContactId,
        createdAt: serverTimestamp(),
        isAdmin: false,
      });
      // Fetch admin user to send welcome message
      const adminSnapshot = await getDocs(
        query(collection(db, "users"), where("isAdmin", "==", true), limit(1))
      );

      if (!adminSnapshot.empty) {
        // Create welcome message
        const welcomeMessageData = {
          text: `Welcome ${formData.firstName} to the Client Portal! If you have any questions, feel free to reach out.`,
          sender: adminSnapshot.docs[0].id,
          receiver: userCredential.user.uid,
          timestamp: new Date(),
          read: false,
        };

        // Add welcome message
        await addDoc(collection(db, "messages"), welcomeMessageData);

        // Update timestamp for both users
        const batch = writeBatch(db);
        batch.update(doc(db, "users", adminSnapshot.docs[0].id), {
          lastMessageTimestamp: welcomeMessageData.timestamp,
        });
        batch.update(doc(db, "users", userCredential.user.uid), {
          lastMessageTimestamp: welcomeMessageData.timestamp,
        });
        await batch.commit();
      }

      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "Failed to create account");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="min-vh-100 bg-gradient">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6} xl={5}>
            <div className="bg-white shadow-lg rounded-4 overflow-hidden">
              <div className="brand-header text-center py-4">
                <img src="/logo.svg" alt="Logo" width="64" height="64" />
                <p className="brand-subtitle">Consumer Protection Networks</p>
                <div className="portal-badge">Client Portal Registration</div>
              </div>

              <div className="p-4 p-md-5">
                <div className="text-center mb-4">
                  <h2 className="welcome-text">Create Account</h2>
                  <p className="text-muted">
                    Register for secure portal access
                  </p>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}

                <Form onSubmit={handleSubmit} className="mb-4">
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <div className="position-relative">
                          <FaUser className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            className="ps-5 custom-input"
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <div className="position-relative">
                          <FaUser className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            className="ps-5 custom-input"
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaEnvelope className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email Address"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaLock className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Create Password"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <div className="position-relative">
                      <FaLock className="position-absolute top-50 translate-middle-y ms-3 text-primary" />
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm Password"
                        className="ps-5 custom-input"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 py-3 custom-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Create Account"
                    )}
                  </Button>
                </Form>
              </div>

              <div className="footer-section text-center py-3">
                <p className="mb-0">
                  Already have an account?{" "}
                  <Link to="/signin" className="signin-link">
                    Sign in here
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
