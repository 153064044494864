import React, { useState, useEffect } from "react";
import { Card, Badge, Col, Row } from "react-bootstrap";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import "./TimeshareList.css";
import { db } from "../../services/firebase";

const TimeshareList = ({ uid }) => {
  const [timeshares, setTimeshares] = useState([]);

  useEffect(() => {
    const timesharesRef = collection(db, "timeshares");
    const q = query(
      timesharesRef,
      where("uid", "==", uid),
      orderBy("updatedAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const timeshareData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTimeshares(timeshareData);
    });

    return () => unsubscribe();
  }, [uid]);

  const getProgressPercentage = (stage) => {
    const stages = {
      Rescission: 10,
      "Welcome Call": 20,
      "Docs Requested": 30,
      Pender: 40,
      "TF Hold": 50,
      Vendor: 60,
      "Non Compliant": 70,
      "CC Dispute/Stop Work": 80,
      Cancelled: 90,
      Completed: 100,
    };
    return stages[stage] || 0;
  };

  const getProgressColor = (stage) => {
    const colors = {
      Rescission: "#6c757d",
      "Welcome Call": "#007bff",
      "Docs Requested": "#17a2b8",
      Pender: "#fd7e14",
      "TF Hold": "#dc3545",
      Vendor: "#ffc107",
      "Non Compliant": "#6610f2",
      "CC Dispute/Stop Work": "#e83e8c",
      Cancelled: "#20c997",
      Completed: "#198754",
    };
    return colors[stage] || "#007bff";
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="timeshare-area">
              <Card className="mb-4">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">My Timeshare Cancellations</h5>
                  <Badge bg="primary" pill>
                    {timeshares.length} Active
                  </Badge>
                </Card.Header>
                <Card.Body>
                  <Row xs={1} md={2} lg={3} className="g-4">
                    {timeshares.length === 0 ? (
                      <Col className="text-center">
                        <Card className="timeshare-card h-100">
                          <Card.Body>
                            <div className="text-center text-muted">
                              No active timeshares available
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ) : (
                      timeshares.map((timeshare) => (
                        <Col key={timeshare.id}>
                          <Card className="timeshare-card h-100">
                            <Card.Body>
                              <div className="d-flex flex-column h-100">
                                <div className="mb-3">
                                  <h6 className="text-primary mb-1">
                                    {timeshare.opportunityName}
                                  </h6>
                                  <div className="d-flex align-items-center gap-2">
                                    <Badge
                                      bg={
                                        timeshare.pipelineStage === "Completed"
                                          ? "success"
                                          : "primary"
                                      }
                                      className="pipeline-badge"
                                    >
                                      {timeshare.pipelineStage}
                                    </Badge>
                                    <small className="text-muted">
                                      Updated{" "}
                                      {timeshare.updatedAt
                                        ?.toDate()
                                        .toLocaleDateString()}
                                    </small>
                                  </div>
                                </div>

                                {timeshare.description && (
                                  <div className="description-box mb-3">
                                    <p className="mb-0">
                                      {timeshare.description}
                                    </p>
                                  </div>
                                )}

                                <div className="mt-auto">
                                  <div
                                    className="progress"
                                    style={{ height: "8px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${getProgressPercentage(
                                          timeshare.pipelineStage
                                        )}%`,
                                        backgroundColor: getProgressColor(
                                          timeshare.pipelineStage
                                        ),
                                      }}
                                      aria-valuenow={getProgressPercentage(
                                        timeshare.pipelineStage
                                      )}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimeshareList;
